<template>
  <div class="acceptsHome">
    <ul class="left">
      <li v-if="0" @click="teb(0)" :class="idx==0?'xz':''">顾客注册</li>
      <li v-if="qxarr.uf_inquiry" @click="teb(qxarr.uf_inquiry.idx, qxarr.uf_inquiry.lx)" :class="idx==qxarr.uf_inquiry.idx?'xz':''">问诊主诉<i v-if="jzyz.wz" class="el-icon-check
"></i></li>
      <li v-if="qxarr.uf_oldglassesstatus" @click="teb(qxarr.uf_oldglassesstatus.idx, 'uf_oldglassesstatus')" :class="idx==qxarr.uf_oldglassesstatus.idx?'xz':''">视力检查<i v-if="jzyz.sljc" class="el-icon-check
"></i></li>
      <li v-if="qxarr.uf_eyeposition" @click="teb(qxarr.uf_eyeposition.idx, 'uf_eyeposition')" :class="idx==qxarr.uf_eyeposition.idx?'xz':''">基础检查<i v-if="jzyz.jcjc" class="el-icon-check
"></i></li>
      <li v-if="qxarr.uf_r_sc" @click="teb(qxarr.uf_r_sc.idx, 'uf_r_sc')" :class="idx==qxarr.uf_r_sc.idx?'xz':''">电脑验光<i v-if="jzyz.dnyg" class="el-icon-check
"></i></li>
      <li v-if="qxarr.uf_examination_ip" @click="teb(qxarr.uf_examination_ip.idx, 'uf_examination_ip')" :class="idx==qxarr.uf_examination_ip.idx?'xz':''">眼压检查<i v-if="jzyz.yyjc" class="el-icon-check
"></i></li>
      <li v-if="qxarr.uf_examination_om" @click="teb(qxarr.uf_examination_om.idx, 'uf_examination_om')" :class="idx==qxarr.uf_examination_om.idx?'xz':''">眼生物测量<i v-if="jzyz.yswjc" class="el-icon-check
"></i></li>

      <li v-if="qxarr.uf_santong" @click="teb(qxarr.uf_santong.idx, 'uf_santong')" :class="idx==qxarr.uf_santong.idx?'xz':''">散瞳/泪分<i v-if="jzyz.st" class="el-icon-check
"></i></li>
      <li v-if="qxarr.uf_slp" @click="teb(qxarr.uf_slp.idx, 'uf_slp')" :class="idx==qxarr.uf_slp.idx?'xz':''">泪道冲洗<i v-if="jzyz.ldcx" class="el-icon-check
"></i></li>
      <li v-if="qxarr.uf_blooddraw" @click="teb(qxarr.uf_blooddraw.idx, 'uf_blooddraw')" :class="idx==qxarr.uf_blooddraw.idx?'xz':''">采血<i v-if="jzyz.cx" class="el-icon-check
"></i></li>
      <li v-if="getxy"  @click="$alert('请在移动端操作', '提示')">校园筛查</li>
    </ul>
    <div class="righto" ref="box">
<!--      <Ling v-if="0" v-on:isLing="getLing"></Ling>-->
<!--      <CustomerDetails v-if="0" v-on:isLing="getLing"></CustomerDetails>-->
      <div>
        <div  v-if="qxarr.uf_inquiry" ref="physicianVisits" class="acceptsASP">
          <h3 style="text-align: center;">问诊主诉</h3>
          <PhysicianVisits v-if="ages >= 18 && is18suijz"></PhysicianVisits>
          <teenagerPhysicianVisits v-if="ages < 18 || !is18suijz"></teenagerPhysicianVisits>

          <PhysicianVisitsTow v-if="ages >= 18 && is18suijz" class="pd1rem"></PhysicianVisitsTow>
          <EyeScene v-if="ages >= 18 && is18suijz" class="pd1rem"></EyeScene>

          <TeenagerSymptoms v-if=" ages < 18 || !is18suijz" class="pd1rem"></TeenagerSymptoms>
          <TeenagerHabit v-if=" ages < 18 || !is18suijz" class="pd1rem"></TeenagerHabit>
          <TeenagerBody v-if=" ages < 18 || !is18suijz" class="pd1rem"></TeenagerBody>
          <MostEye v-if=" ages >= 18 && is18suijz" class="pd1rem"></MostEye>
        </div>

        <div>
          <div v-if="qxarr.uf_oldglassesstatus" class="acceptsASP" style="padding: 1rem 0; border-bottom: 1px dotted #000000" ref="eyeTest">
            <h3 class="padd-top30">视力检查</h3>
            <eyeCheck :idx="idx"></eyeCheck>
<!--            <h3>佩戴情况</h3>-->
<!--            <Wearing :idx="idx"></Wearing>-->
          </div>

          <div v-if="qxarr.uf_eyeposition" class="acceptsASP" ref="basisText"  style="padding-top: 0.5rem;padding-bottom: 1rem; border-bottom: 1px dotted #000000">
            <h3 class="padd-top30">基础检查</h3>
            <EyeTests :idx="idx"></EyeTests>

<!--            <h3>眼底检查</h3>-->
<!--            <FundusTest :idx="idx"></FundusTest>-->
          </div>

          <div v-if="qxarr.uf_r_sc"  class="acceptsASP" ref="computerOptometry"  style="padding: 1.3rem 0; border-bottom: 1px dotted #000000">
<!--            <h3 class="padd-top30">散瞳验光</h3>-->
<!--            <Refraction :idx="idx"></Refraction>-->

<!--            <h3>小瞳验光</h3>-->
            <SmallPupilOptometry :types="0"></SmallPupilOptometry>

<!--            <h3>框架试戴</h3>-->
<!--            <TryFramework></TryFramework>-->

<!--            <h3>方案建议</h3>-->
<!--            <Proposal></Proposal>-->
          </div>

          <div v-if="qxarr.uf_examination_ip" class="acceptsASP" ref="IntraocularPressure"  style="padding: 1rem 0; border-bottom: 1px dotted #000000">
            <h3 class="padd-top30">眼压检查</h3>
            <IntraocularPressure ref="IntraocularPressures" :idx="idx"></IntraocularPressure>
          </div>

          <EyeBiologyTest v-if="qxarr.uf_examination_om" class="acceptsASP" ref="EyeBiologyTest" style="padding: 1rem 0; border-bottom: 1px dotted #000000"></EyeBiologyTest>


          <Tears class="acceptsASP" ref="EyeBiologyTest" style="padding: 1rem 0; border-bottom: 1px dotted #000000"></Tears>
          <Lacrimalirrigation class="acceptsASP" ref="EyeBiologyTest" style="padding: 1rem 0; border-bottom: 1px dotted #000000"></Lacrimalirrigation>
          <BloodCollection class="acceptsASP" ref="EyeBiologyTest" style="padding: 1rem 0; border-bottom: 1px dotted #000000"></BloodCollection>
        </div>
      </div>



      <div style="height: 300px;"></div>
      <el-button @click="save" class="bottoms" type="primary">{{bt}}保存</el-button>
    </div>
  </div>
</template>

<script>
import physicianVisits from "@/store/moddules/physicianVisits";
import specialSurveys from '@/publicFn/specialSurveys'
import {Loading} from "element-ui";
import pf from '../../publicFn/baseFn'


export default {
  name: "acceptsHome",
  data() {
    return {
      idx: 0,
      isuserLing: true,
      bt: '问诊',
      age: '',
      saves: new Map(),
      xzstr: 'uf_inquiry',
      allys: '',
      qxarr: {},
      btmap: new Map(),
      TimeMap: new Map(),
      times: 0,
      is18suijz: false,
      jzyz: {
            wz: false,
            sljc: false,
            jcjc:false,
            dnyg: false,
            yyjc: false,
            yswjc: false,
            ldcx: false,
            cx: false,
            st: false
      }
    }
  },
  watch: {
    idx: function (n, o) {
      this.bt = this.btmap.get(this.xzstr)
      if (this.idx != 0) {
        if (!this.$store.state.users.CsUser) {
          this.$alert('用户未登陆，请先登陆！', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
         return
        }
        if (!this.$store.state.physicianVisits.xzConsulting) {
          this.$alert('请选择咨询或新建咨询', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        }
      }
      // this.setTime(this.xzstr)
    },
    '$store.state.physicianVisits.jump': function (n, o) {
      if (n > 0) {
        this.teb(1)
      }
    },
    "$store.state.isbc.jzyz": {
        deep: true,
        handler: function (n ,o) {
            this.jzyz = JSON.parse(JSON.stringify(n))
        }
    },
    "$store.state.isShow.keyh": {
      handler(n, o) {
        // console.dir(document.body.offsetHeight)
        // console.dir(this.$refs.boxs)
        if (n > document.body.offsetHeight - 290) {
          this.$refs.box.scrollTop = this.$refs.box.scrollTop + (n - (document.body.offsetHeight - 290)) + 30
        }
      }
    }
  },
  computed: {
    ages() {
      return this.$store.state.users.CsAge
    },
    getxy() {
      if (this.$store.state.users.jurisdiction.uf_screen) {
        return true
      }else {
        return false
      }
    }
  },
  created() {


    const lg = this.$loading({
      text: '加载中...'
    })
    this._api.physicianVisits.getTeenagerChoose(this.$store.state.physicianVisits.xzConsulting)
        .then(res => {
          lg.close()
          if (!res.GetResult) {
            this.is18suijz = true
          }
        })
    this.idx = 1
    this.jzyz = JSON.parse(JSON.stringify(this.$store.state.isbc.jzyz))
    if (this.$route.query.type) {
      this.isuserLing = false
    }
    this.times = (new Date()).valueOf()
    this.isshow()
    this.getjc()
  },
  mounted() {
    this.btmap.set('uf_inquiry', '问诊')
    this.btmap.set('uf_oldglassesstatus', '视力检查')
    this.btmap.set('uf_eyeposition', '基础检查')
    this.btmap.set('uf_r_sc', '电脑验光')
    this.btmap.set('uf_examination_ip', '眼压检查')
    this.btmap.set('uf_examination_om', '眼生物测量')
    this.btmap.set('uf_santong', '散瞳/泪分')
    this.btmap.set('uf_slp', '泪道冲洗')
    this.btmap.set('uf_blooddraw', '采血')

    this.saves.set('uf_inquiry', this.savePhysicianVisits)
    this.saves.set('uf_oldglassesstatus', this.saveVisionTest)
    this.saves.set("uf_eyeposition", specialSurveys.saveEyeBuTest)
    this.saves.set("uf_r_sc", specialSurveys.saveRefractive)
    this.saves.set("uf_examination_ip", '')
    this.saves.set("uf_examination_om", this._api.eyeTest.saveEyeBiologyTest)
    this.saves.set("uf_santong", this.save2)
    this.saves.set("uf_slp", this.save2)
    this.saves.set("uf_blooddraw", this.save2)

    this.$refs.box.addEventListener('scroll', this.rolling)
    let box = document.getElementsByClassName('acceptsHome')[0]
    this.allys = box.getElementsByClassName('acceptsASP')
    // this.idx = this.$store.state.physicianVisits.idxs
    if (this.$store.state.users.CsUser) {
      this.isuserLing = false
    }

    this.TimeMap.set('uf_inquiry', 'inquiry_customerSay')
    this.TimeMap.set('uf_oldglassesstatus', 'EC_eyesightCheck')
    this.TimeMap.set('uf_eyeposition', 'EC_baseCheck')
    this.TimeMap.set('uf_r_sc', 'EC_computerOptometry')
    this.TimeMap.set('uf_examination_ip', 'EC_ophtha_IP')
    this.TimeMap.set('uf_examination_om', 'EC_ophtha_OM')
    this.TimeMap.set('uf_santong', 'EC_Santong')
    this.TimeMap.set('uf_slp', 'EC_SLP')
    this.TimeMap.set('uf_blooddraw', 'EC_BloodDraw')
  },
  methods: {
    teb(idx,str) {
      // console.log(idx, str)
      this.idx = idx
      this.xzstr = str
      switch (str) {
        case 'uf_inquiry':
          this.bt= '问诊'
          break
        case 'uf_oldglassesstatus':
          this.bt= '视力检查'
          break
        case "uf_eyeposition":
          this.bt= '基础检查'
          break
        case "uf_r_sc":
          this.bt= '电脑验光'
          break
        case "uf_examination_ip":
          this.bt= '眼压检查'
          break
        case "uf_santong":
          this.bt= '散瞳/泪分'
          break
        case "uf_slp":
          this.bt= '泪道冲洗'
          break
        case "uf_blooddraw":
          this.bt= '采血'
          break
        case "uf_examination_om":
          this.bt= '眼生物测量'
          break
      }
      // console.log(idx-1, this.bt)
      this.$refs.box.scrollTop = this.getH(idx-1)
    },
    rolling() {
      let dhtH = this.$refs.box.scrollTop
      // console.log(dhtH,this.getH(6), this.getH(7))
      if (dhtH >= this.getH(0) && dhtH <= this.getH(1)*0.9) {
        this.idx = 1
        this.$store.commit('physicianVisits/upIdxs', 1)
      }else if (dhtH > this.getH(1)*0.9 && dhtH < this.getH(2)*0.9) {
        this.idx = 2
        this.$store.commit('physicianVisits/upIdxs', 2)
      }else if (dhtH > this.getH(2)*0.9 && dhtH < this.getH(3)*0.9) {
        this.idx = 3
        this.$store.commit('physicianVisits/upIdxs', 3)
      }else if(dhtH > this.getH(3)*0.9 && dhtH < this.getH(4)*0.9){
        this.idx = 4
        this.$store.commit('physicianVisits/upIdxs', 4)
      }else if (dhtH > this.getH(4)*0.9 && dhtH < this.getH(5)*0.9) {
        this.idx = 5
        this.$store.commit('physicianVisits/upIdxs', 5)
      }else if (dhtH > this.getH(5)*0.95&& dhtH < this.getH(6)*0.9){
        this.idx = 6
        this.$store.commit('physicianVisits/upIdxs', 6)
      }
      else if (dhtH > this.getH(6)*0.95 && dhtH < this.getH(7)*0.9){
        this.idx = 7
        this.$store.commit('physicianVisits/upIdxs', 7)
      }
      else if (dhtH > this.getH(7)*0.95&& dhtH < this.getH(8)*0.9){
        this.idx = 8
        this.$store.commit('physicianVisits/upIdxs', 8)
      }
      else if (dhtH > this.getH(8)*0.95){
        this.idx = 9
        this.$store.commit('physicianVisits/upIdxs', 9)
      }
      for (let k in this.qxarr) {
        if (this.qxarr[k].idx == this.idx) {
          this.xzstr = this.qxarr[k].lx
        }
      }
    },
    //接受来自登陆组件的传值
    getLing(val) {
      this.isuserLing = val
    },

    save() {
      if (!this.$store.state.users.CsUser) {
        this.$alert('用户登陆以后才能保存', '提示')
        return;
      }

      let times = ((new Date().valueOf() - this.times)/1000)
      this._api.publicApi.addTime(this.TimeMap.get(this.xzstr), times)
      this.jsTime(this.TimeMap.get(this.xzstr), 1)

      if (this.xzstr == 'uf_examination_ip') {
        return  this.$refs.IntraocularPressures.save()
      }

      // if (this.xzstr == 'uf_examination_om') {
      //   return  this.$refs.EyeBiologyTest
      // }

      if (this.xzstr == 'uf_r_sc') {
        let ob = this.$refs.computerOptometry.getElementsByClassName('cw')
        if (ob > 0) {

        }
      }
      // console.log(this.xzstr)
      if (this.saves.get(this.xzstr)) {this.saves.get(this.xzstr)()}
    },

    save2() {
      switch (this.xzstr){
        case "uf_santong":
          this._api.physicianVisits.savTears()
          this._api.eyeTest.saveAppearsTest()
          break
        case "uf_slp":
          this._api.physicianVisits.savLacrimalirrigation()
          break
        case "uf_blooddraw":
          this._api.physicianVisits.savbloodCollection()
          break
      }
    },

    savePhysicianVisits() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
      });
      let times = ((new Date().valueOf() - this.times)/1000)
      // console.log(times)
      this._api.publicApi.addTime('inquiry_customerSay,inquiry_customerGenderAndAge,inquiry_specialNeeds,inquiry_mainScenes,inquiry_distance,inquiry_duration,inquiry_diseasesHistory', times)
      this.jsTime(this.TimeMap.get(this.xzstr), 1)

      if (this.$store.state.users.CsAge < 18) {
        this._api.physicianVisits.saveTeenager()
            .then(res => {
              loadingInstance.close()
              if (res.UpdateResult) {
                  this.$store.commit('isbc/upjzyz', {
                      val: true,
                      keys: 'wz'
                  })
                this.$alert('保存问诊保存成功', '提示')
              }else {
                this.$alert('保存问诊保存失败，请稍后再试', '提示')
              }
            })
            .catch(err => {
              loadingInstance.close()
            })
      }else {
        this._api.physicianVisits.physicianVisitsSave()
            .then(res => {
              loadingInstance.close()
              if (res.UpdateResult) {
                  this.$store.commit('isbc/upjzyz', {
                      val: true,
                      keys: 'wz'
                  })
                this.$alert('保存问诊成功', '提示')
              }else {
                this.$alert('保存问诊失败，请稍后再试', '提示')
              }
            })
        .catch(err => {
          loadingInstance.close()
        })
      }
    },

    saveVisionTest() {
      if (this.$store.state.eyeTest.datas.S_OP_HI_IfGlasses == '1') {
        if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_SPHOD) {
          if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_VAOD === '') {
            return this.$alert('旧镜VA或球镜不能为空', '提示')
          }
        }
        if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_VAOD) {
          if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_SPHOD === '') {
            return this.$alert('旧镜VA或球镜不能为空', '提示')
          }
        }
        if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_SPHOS) {
          if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_VAOS === '') {
            return this.$alert('旧镜VA或球镜不能为空', '提示')
          }
        }
        if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_VAOS) {
          if (this.$store.state.eyeTest.datas.S_OP_HI_Frame_SPHOS === '') {
            return this.$alert('旧镜VA或球镜不能为空', '提示')
          }
        }
      }

      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
      });

      if (this.$store.state.Time.timeObj.EC_eyesightCheck.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.EC_eyesightCheck.ENTER)/1000 + Number(this.$store.state.Time.timeObj.EC_eyesightCheck.EXIT)
        this._api.publicApi.addTime('EC_eyesightCheck,EC_oldGlassesCheck', times)
        this.jsTime(this.TimeMap.get(this.xzstr), 1)
      }


      this._api.eyeTest.visionTestSave()
      .then(res => {
        loadingInstance.close()
        if (res.UpdateResult) {
          this.$store.commit('isbc/upjzyz', {
              val: true,
              keys: 'sljc'
          })
          this.$alert('视力检查保存成功', '提示')
        }else {
          this.$alert('视力检查保存失败，请稍后再试', '提示')
        }
      })
    },

    getH(idx) {
      if (idx == 0) return 0
      let h = 0
      for (let i = 0; i < idx; i++) {
        h += this.allys[i].offsetHeight
      }
      return h
    },

    isshow() {
      let obj = this.$store.state.users.jurisdiction,
          arr = [],
          items = {
            uf_inquiry: '',
            uf_oldglassesstatus: '',
            uf_eyeposition: '',
            uf_r_sc: '',
            uf_examination_ip: '',
            uf_examination_om: '',
            uf_santong: '',
            uf_slp: '',
            uf_blooddraw: ''
          }
      if (obj.uf_inquiry) arr.push({lx: obj.uf_inquiry});
      if (obj.uf_oldglassesstatus) arr.push({lx: obj.uf_oldglassesstatus});
      if (obj.uf_eyeposition) arr.push({lx: obj.uf_eyeposition});
      if (obj.uf_r_sc) arr.push({lx: obj.uf_r_sc});
      if (obj.uf_examination_ip) arr.push({lx: obj.uf_examination_ip});
      if (obj.uf_examination_om) arr.push({lx: obj.uf_examination_om});

      if (obj.uf_santong) arr.push({lx: obj.uf_santong});
      if (obj.uf_slp) arr.push({lx: obj.uf_slp});
      if (obj.uf_blooddraw) arr.push({lx: obj.uf_blooddraw});

      arr.forEach((item, idx) => {
        item.idx = idx+1
      })
      arr.forEach(item => {
         items[item.lx] = item
      })
      this.qxarr = items
      items = null
      obj = null
    },

    setTime(str) {

      // console.log(      this.TimeMap.get(str))
      // console.log(this.TimeMap.keys())
      let arr =  Array.from(this.TimeMap.keys())
      for (let i = 0; i <arr.length; i++) {
        if (str == arr[i]) {
          this.cshTime(this.TimeMap.get(str))
        }else {
          this.jsTime(this.TimeMap.get(arr[i]))
        }
      }
    },

    cshTime(keys) {
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    },

    getjc() {
      this._api.publicApi.getExaminationFinish()
      .then(res => {
        if (res.GetResult && res.GetResult.ChooseStep) {
          let arr = res.GetResult.ChooseStep.split(',')
          for (let i = 0; i < arr.length; i++) {
            switch (arr[i]) {
              case 'HisInfo':
                this.$store.commit('isbc/upjzyz', {
                  val: true,
                  keys: 'sljc'
                })
                break
              case 'EyesCheck':
                this.$store.commit('isbc/upjzyz', {
                  val: true,
                  keys: 'jcjc'
                })
                break
              case 'Refractive':
                this.$store.commit('isbc/upjzyz', {
                  val: true,
                  keys: 'dnyg'
                })
                break
              case 'CL_RE_IP':
                this.$store.commit('isbc/upjzyz', {
                  val: true,
                  keys: 'yyjc'
                })
                break
              case 'CL_RE_OM':
                this.$store.commit('isbc/upjzyz', {
                  val: true,
                  keys: 'yswjc'
                })
                break
              // case 'CL_RE_OM':
              //   this.$store.commit('isbc/upjzyz', {
              //     val: true,
              //     keys: 'yswjc'
              //   })
              //   break
              // case 'CL_RE_OM':
              //   this.$store.commit('isbc/upjzyz', {
              //     val: true,
              //     keys: 'yswjc'
              //   })
              //   break
              // case 'CL_RE_OM':
              //   this.$store.commit('isbc/upjzyz', {
              //     val: true,
              //     keys: 'yswjc'
              //   })
              //   break
            }
          }
          // this.jzyz = JSON.parse(JSON.stringify(this.$store.state.isbc.jzyz))
        }
      })
    }
  },

  components: {
    Ling: () => import('@/views/accepts/customer/customersLog'),
    eyeCheck: () => import('@/components/specialSurveys/examinationVisualAcuityHome.vue'),
    Wearing: () => import('@/views/specialSurvey/wearing.vue'),
    PhysicianVisits: () => import('@/views/accepts/physicianVisits'),
    teenagerPhysicianVisits: () => import('@/components/PhysicianVisits/teenagerPhysicianVisits'),
    PhysicianVisitsTow: () => import('@/components/PhysicianVisits/PhysicianVisitsTow'),
    EyeScene: ()=> import('@/components/PhysicianVisits/eyeScene'),
    MostEye: () => import('@/components/PhysicianVisits/mostEye'),
    EyeTests: () => import('@/components/specialSurveys/eyePart/basedInspection'),
    //FundusTest: () => import('@/components/eyeTest/eyeBaseTest/fundusTest'),
    Refraction: () => import('@/components/specialSurveys/refractive/refraction'),
    SmallPupilOptometry: () => import('@/components/specialSurveys/refractive/SmallPupilOptometry'),
    TryFramework: () => import('@/components/specialSurveys/refractive/TryFramework'),
    Proposal: () => import('@/components/specialSurveys/refractive/proposal'),
    IntraocularPressure: () => import('@/views/specialSurvey/intraocularPressure'),
    CustomerDetails: () => import('@/views/accepts/customer/customerDetails'),
    TeenagerSymptoms: () => import('@/components/PhysicianVisits/teenagerSymptoms'),
    TeenagerHabit: () => import('@/components/PhysicianVisits/teenagerHabit'),
    TeenagerBody: () => import('@/components/PhysicianVisits/teenagerBody'),
    EyeBiologyTest: () => import('@/views/specialSurvey/eyeBiologyTest'),
    Tears: () => import("@/components/PhysicianVisits/tears"),
    Lacrimalirrigation: () => import("@/components/PhysicianVisits/lacrimalirrigation"),
    BloodCollection: () => import("@/components/PhysicianVisits/bloodCollection"),
  },

  beforeDestroy() {
    if (this.$store.state.Time.timeObj[this.TimeMap.get(this.xzstr)].ENTER != 0) {
      this.jsTime(this.TimeMap.get(this.xzstr))
    }
  }
}
</script>

<style scoped lang="scss">
   h3{padding: 15px 0;}
  .acceptsHome {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .left {
    width: 12vw;
    height: 76vh;
    border-right: 1px solid #E8E8EB;
    text-align: center;
    position: relative;
    li {
      padding: 20px 0;
      font-size: .2rem;
      cursor: pointer;
      i {
          position: absolute;
          right: 1vw;
          font-weight: bold;
      }
    }
    .xz {background: #EEEEEE;}
  }
  .righto {
    height: 76vh;
    width: 88vw;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    h3 {
      width: 90%;
      margin: 20px auto;
      text-align: left;
    }
  }
  //.righto::-webkit-scrollbar { width: 0 !important }
  .bottomppt {
    width: 100%;
    position: fixed;
    bottom: 10vh;
    left: 4%;
    .bt {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  .padd-top30 {margin-top: 1rem !important;}
  .bottoms {
    position: fixed;
    bottom: 1.6rem;
    right: 10px;
  }
  .borders {border-bottom: 1px dotted #5a5959; padding-bottom: 20px;}
  .pd1rem {padding-top: 1rem !important;}
</style>
